@if (loading) {
  <app-loading></app-loading>
}

@for (e of enrollments; track e) {
  <div>
    @if (e.plan; as p) {
      <div class="mb-8">
        <!-- <h2><fa-icon [icon]="faCircleInfo"></fa-icon> Plan</h2> -->
        <div class="grid md:grid-cols-3 gap-4 mb-4">
          @if (getActiveType(e); as type) {
            <div>
              @if (type === 'active') {
                <div class="card card-success mb-4">
                  <div class="card-body flex-row justify-between items-center">
                    <div class="avatar">
                      <div
                        class="mask mask-squircle w-16 h-16 text-success bg-white mr-4">
                        <div class="flex justify-center items-center h-full">
                          <fa-icon [icon]="faCheck" class="text-2xl"></fa-icon>
                        </div>
                      </div>
                    </div>
                    <div class="grow">
                      <div class="card-title">Enrollment active</div>
                      <p>
                        since:
                        {{ e.details?.effectiveDate?.timestamp | date: 'shortDate' }}
                      </p>
                    </div>
                  </div>
                </div>
              }
              @if (type === 'pending') {
                <div class="card card-primary mb-4">
                  <div class="card-body flex-row justify-between items-center">
                    <div class="avatar">
                      <div
                        class="mask mask-squircle w-16 h-16 text-primary bg-white mr-4">
                        <div class="flex justify-center items-center h-full">
                          <fa-icon [icon]="faEllipsis" class="text-2xl"></fa-icon>
                        </div>
                      </div>
                    </div>
                    <div class="grow">
                      <p>Enrollment Effective Date</p>
                      <div class="card-title">
                        {{ e.details?.effectiveDate?.timestamp | date: 'shortDate' }}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
          @if (e.premium?.monthly !== null && e.premium?.monthly !== undefined) {
            <div class="card card-primary mb-4">
              <div class="card-body flex-row justify-between items-center">
                <div class="avatar">
                  <div
                    class="mask mask-squircle w-16 h-16 text-primary bg-white mr-4">
                    <div class="flex justify-center items-center h-full">
                      <fa-icon [icon]="faDollarSign" class="text-2xl"></fa-icon>
                    </div>
                  </div>
                </div>
                <div class="grow">
                  <div class="card-title">
                    {{ e.premium?.monthly | currency }}
                  </div>
                  <p>Monthly Premium</p>
                </div>
              </div>
            </div>
          }
        </div>
        <div class="grid md:grid-cols-2 gap-4 mb-4">
          <div>
            <div class="card mb-4">
              <div class="card-body justify-start">
                <div class="card-title mb-4">
                  <fa-icon [icon]="faNoteMedical"></fa-icon> Plan
                </div>
                <div class="flex items-center">
                  @if (
                    e.carrier &&
                    e.carrier.id &&
                    carrierMap[e.carrier.id] &&
                    carrierMap[e.carrier.id].profileImage
                  ) {
                    <img
                      class="w-32 h-16 object-contain mr-4"
                      [src]="carrierMap[e.carrier.id].profileImage" />
                  }
                  <!-- <div class="avatar mr-4" *ngIf="carriers[e.id]?.logo">
                  <div class="mask mask-squircle w-16 h-16">
                  </div>
                </div> -->
                <div class="grow">
                  @if (e.carrier && e.carrier.id) {
                    @if (carrierMap[e.carrier.id]; as carrier) {
                      <div>
                        <p>{{ carrier?.name }}</p>
                      </div>
                    }
                  }
                  <!-- <h2 class="pb-0">
                  <span>{{ carrierMap p.carrierName }}</span>
                  @if (!production) {
                    <button
                      class="btn btn-ghost"
                      (click)="openInNewTab(p.providerDirectoryWebAddress)">
                      <fa-icon [icon]="faArrowUpRightFromSquare"></fa-icon>
                    </button>
                  }
                </h2>
                <h4 class="mb-4">{{ p.name }}</h4> -->
              </div>
            </div>
            <!-- <p>{{ p.displayId }}-{{ p.segmentId }}</p> -->
            <div class="flex">
              <div class="badge badge-primary mr-2 mb-2">
                @if (e.plan.planType && planTypeMap[e.plan.planType]) {
                  Type: {{ planTypeMap[e.plan.planType] }}
                } @else {
                  Type: {{ e.plan.planType }}
                }
              </div>
              <div class="badge badge-primary mr-2 mb-2">
                @if (e.plan.policyType && policyTypeMap[e.plan.policyType]) {
                  Policy Type: {{ policyTypeMap[e.plan.policyType] }}
                } @else {
                  Policy Type: {{ e.plan.policyType }}
                }
              </div>
            </div>
          </div>
        </div>
        @if (e.consumer; as c) {
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                <fa-icon [icon]="faUser" class="mr-2"></fa-icon>
                PersonalInformation
              </div>
              <h2>{{ c.firstName }} {{ c.lastName }}</h2>
              <table class="table">
                <thead>
                  <tr>
                    <th colspan="2">General Information</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Home Address</td>
                    <td>
                      <span></span>
                      @if (c.address?.line1) {
                        <span
                          >{{ c.address?.line1 }}<br
                        /></span>
                      }
                      @if (c.address?.line2) {
                        <span
                          >{{ c.address?.line2 }}<br
                        /></span>
                      }
                      @if (c.address?.city) {
                        <span
                          >{{ c.address?.zip ?? '' }} {{ c.address?.city }}<br
                        /></span>
                      }
                      @if (c.address?.county) {
                        <span
                          >{{ c.address?.county }}<br
                        /></span>
                      }
                      @if (c.address?.state) {
                        <span>{{ c.address?.state }}</span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Date of Birth</td>
                    <td>
                      @if (c.birthDate) {
                        {{ c.birthDate.month }}-{{ c.birthDate.day }}-{{
                        c.birthDate.year
                        }}
                      } @else {
                        N/A
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>
                      {{ c.gender ? genderLookupMap[c.gender] : 'Not provided' }}
                    </td>
                  </tr>
                  <tr>
                    <td>Language</td>
                    <td>{{ c.language }}</td>
                  </tr>
                  <tr>
                    <td>Medicare Number</td>
                    <td>{{ c.medicare?.number }}</td>
                  </tr>
                  @if (e?.medicaidInfo?.number) {
                    <tr>
                      <td>Medicaid Number</td>
                      <td>{{ e?.medicaidInfo?.number }}</td>
                    </tr>
                  }
                </tbody>
                <thead>
                  <tr>
                    <th colspan="2">Communications</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Phone Number</td>
                    <td>{{ c.phone }}</td>
                  </tr>
                  <tr>
                    <td>E-Mail</td>
                    <td>
                      @if (c.email) {
                        <p>{{ c.email }}</p>
                      }
                      <!-- @if (!production) {
                      <div class="join" *ngIf="!c.email">
                        <div>
                          <input
                            class="input input-bordered join-item"
                            ngDefaultControl
                            type="text"
                            placeholder="Enter your email"
                            [(ngModel)]="newEmail"
                            (keydown.enter)="saveEmailOk(i, true)" />
                        </div>
                        <div class="indicator">
                          <button
                            class="btn join-item"
                            (click)="saveEmailOk(i, true)">
                            <fa-icon [icon]="faSave" class="mr-2"></fa-icon>
                            Save Email
                          </button>
                        </div>
                      </div>
                      }-->
                      <br />
                      @if (e.consumer.okToEmail === false && !c.email) {
                        <div
                          class="flex items-center my-2"
                          >
                          <div class="avatar">
                            <div
                              class="mask mask-squircle w-8 h-8 bg-error text-white mr-4">
                              <div class="flex justify-center items-center h-full">
                                <fa-icon [icon]="faTimes"></fa-icon>
                              </div>
                            </div>
                          </div>
                          <p>No Email provided</p>
                        </div>
                      }
                      <!-- <button
                      class="btn btn-primary"
                      *ngIf="e.okToEmail === false && c.email"
                      (click)="saveEmailOk(i, false)">
                      Allow, that we contact you
                    </button> -->
                    @if (e.consumer.okToEmail === true) {
                      <div
                        class="flex items-center my-2"
                        >
                        <div class="avatar">
                          <div
                            class="mask mask-squircle w-8 h-8 bg-success text-white mr-4">
                            <div class="flex justify-center items-center h-full">
                              <fa-icon [icon]="faCheck"></fa-icon>
                            </div>
                          </div>
                        </div>
                        <p>We can email you</p>
                      </div>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <p>
            currently only approximately based on zip. If this should be
            included in the final version, the exact location will be set
          </p>
          <div
            class="rounded-box overflow-hidden mb-8"
            *ngIf="showGoogleMaps">
            <google-map [options]="googleMapsOptions" width="100%">
              <map-marker
                *ngFor="let markerPosition of googleMapsMarkerPositions"
                [position]="markerPosition"
              [options]="googleMapsMarkerOptions"></map-marker>
            </google-map>
          </div> -->
          @if (!production) {
            <button
              class="btn btn-outline"
              routerLink="/consumer/search/e/soa/result">
              <fa-icon [icon]="faSignature" class="mr-2"></fa-icon>
              Open Scope of Appointment
            </button>
          }
        </div>
      </div>
    }
  </div>
  <div class="card">
    <div class="card-body">
      <div class="card-title mb-4">
        <fa-icon [icon]="faUserTie"></fa-icon> Agent
      </div>
      <div>
        <div class="flex items-center mb-4">
          <div class="avatar mr-4">
            @if (e.agent?.profileImage) {
              <div
                class="mask mask-squircle w-32 h-32 text-white bg-primary"
                >
                <img [src]="e.agent?.profileImage" />
              </div>
            }
          </div>
          <!-- <div
          class="avatar"
                *ngIf="
                  e.agent && e.agent.broker && e.agent.broker.profileImage
                ">
          <div class="mask mask-squircle w-32 h-32">
            <img [src]="e.agent.broker.profileImage" />
          </div>
        </div> -->
      </div>
      <div class="card-title mb-4">
        {{ e.agent?.firstName ?? '' }} {{ e.agent?.lastName ?? '' }}
      </div>
      <!--<div class="flex flex-wrap mb-4">
      <div class="badge badge-neutral mr-2 mb-2">
        NPN: {{ e.agent?.npn }}
      </div>
      <div class="badge badge-neutral mr-2 mb-2">
        Resident State: {{ e.agent?.stateAbbr }}
      </div>
    </div> -->
    <div class="mb-4">
      <p class="mb-2">
        <!-- <fa-icon [icon]="faEnvelope" class="mr-2"></fa-icon> -->
        NPN: {{ e.agent?.npn }}
      </p>
      @if (e.agent && e.agent.address && e.agent.address.state) {
        <p
          class="mb-2"
          >
          <!-- <fa-icon [icon]="faEnvelope" class="mr-2"></fa-icon> -->
          Resident State: {{ e.agent.address.state }}
        </p>
      }
      <!-- <h4>I will help you with any question, just reach out to me</h4> -->
      @if (e.agent?.email) {
        <p class="mb-2">
          <fa-icon [icon]="faEnvelope" class="mr-2"></fa-icon>
          {{ e.agent?.email }}
          <!-- <fa-icon [icon]="faEnvelope" class="mr-2"></fa-icon
          >{{ 'trees@teamifg.com' }} -->
        </p>
      }
      @if (e.agent?.phone) {
        <p class="mb-2">
          <fa-icon [icon]="faPhone" class="mr-2"></fa-icon>
          {{ e.agent?.phone }}
          <!-- <fa-icon [icon]="faEnvelope" class="mr-2"></fa-icon
          >{{ 'trees@teamifg.com' }} -->
        </p>
      }
    </div>
    @if (!production) {
      <div class="grid xl:grid-cols-3 gap-4">
        <button
          class="btn btn-primary flex flex-row items-center h-auto flex-nowrap">
          <div class="avatar m-2 -mx-2">
            <div
              class="mask mask-circle w-12 h-12 text-primary bg-white">
              <div class="flex justify-center items-center h-full">
                <fa-icon [icon]="faUserRobot" class="text-xl"></fa-icon>
              </div>
            </div>
          </div>
          <p class="mb-0 grow">Plan Support</p>
        </button>
        <!-- <button
        class="btn btn-primary flex flex-row items-center h-auto flex-nowrap"
        *ngIf="e.agent?.email"
        (click)="openEmail(e.agent?.email)">
        <div class="avatar m-2 -mx-2">
          <div class="mask mask-circle w-12 h-12 text-primary bg-white">
            <div class="flex justify-center items-center h-full">
              <fa-icon [icon]="faEnvelope" class="text-xl"></fa-icon>
            </div>
          </div>
        </div>
        <p class="mb-0 grow">Write me an email</p>
      </button>
      <button
        class="btn btn-primary flex flex-row items-center h-auto flex-nowrap">
        <div class="avatar m-2 -mx-2">
          <div class="mask mask-circle w-12 h-12 text-primary bg-white">
            <div class="flex justify-center items-center h-full">
              <fa-icon
                [icon]="faCalendarDays"
              class="text-xl"></fa-icon>
            </div>
          </div>
        </div>
        <p class="mb-0 grow">Book a Meeting</p>
      </button>
      <button
        class="btn btn-primary flex flex-row items-center h-auto flex-nowrap">
        <div class="avatar m-2 -mx-2">
          <div class="mask mask-circle w-12 h-12 text-primary bg-white">
            <div class="flex justify-center items-center h-full">
              <fa-icon [icon]="faUserRobot" class="text-xl"></fa-icon>
            </div>
          </div>
        </div>
        <p class="mb-0 grow">Write Chatbot</p>
      </button> -->
    </div>
  }
</div>
</div>
</div>
<div class="card">
  <div class="card-body">
    <div>
      <div class="card-title mb-4">
        <fa-icon [icon]="faHandHoldingMedical"></fa-icon> Medicare
      </div>
      @if (e.consumer && e.consumer.medicare) {
        <p class="mb-4">
          Medicare Number: {{ e.consumer.medicare.number }}
        </p>
      }
      <div class="grid lg:grid-cols-2 xl:grid-cols-4 mb-4">
        <div class="flex flex-col justify-center mr-4">
          <h4>Hospital (Part A)</h4>
          <p>effective date</p>
          <h3>
            {{ e.details?.partAEffectiveDate?.month }} -
            {{ e.details?.partAEffectiveDate?.day }} -
            {{ e.details?.partAEffectiveDate?.year }}
          </h3>
        </div>
        <div class="flex flex-col justify-center mr-4">
          <h4>Medical (Part B)</h4>
          <p>effective date</p>
          <h3>
            {{ e.details?.partBEffectiveDate?.month }} -
            {{ e.details?.partBEffectiveDate?.day }} -
            {{ e.details?.partBEffectiveDate?.year }}
          </h3>
        </div>
        <!-- <div
        class="flex flex-col justify-center mr-4"
        *ngIf="e.veteranStatus && e.veteranStatus !== 'NO_ANSWER'">
        <h4>Veteran Status</h4>
        <h3>{{ veteranLookupMap[e.veteranStatus].title }}</h3>
      </div> -->
      <!-- <div
      class="flex flex-col justify-center mr-4"
      *ngIf="e.work === true">
      <h4>Work</h4>
      <h3>Working</h3>
    </div>
    <div
      class="flex flex-col justify-center mr-4"
      *ngIf="e.work === false">
      <h4>Work</h4>
      <h3>Not Working</h3>
    </div> -->
  </div>
  <!-- <div class="flex items-center mb-4" *ngIf="!e.medicaid?.has">
  <div class="avatar">
    <div
      class="mask mask-squircle w-8 h-8 bg-error text-white mr-4">
      <div class="flex justify-center items-center h-full">
        <fa-icon [icon]="faTimes"></fa-icon>
      </div>
    </div>
  </div>
  <p>You don't have Medicaid</p>
</div> -->
@if (e.medicaidInfo?.has) {
  <div class="flex items-center mb-4">
    <div class="avatar">
      <div
        class="mask mask-squircle w-8 h-8 bg-success text-white mr-4">
        <div class="flex justify-center items-center h-full">
          <fa-icon [icon]="faCheck"></fa-icon>
        </div>
      </div>
    </div>
    <p>You are Medicaid eligible</p>
  </div>
}
@if (e.medicaidInfo?.number) {
  <div class="flex items-center mb-4">
    <div class="avatar">
      <div
        class="mask mask-squircle w-8 h-8 bg-success text-white mr-4">
        <div class="flex justify-center items-center h-full">
          <fa-icon [icon]="faCheck"></fa-icon>
        </div>
      </div>
    </div>
    <p>
      Medicaid Number:
      {{ e.medicaidInfo?.number }}
    </p>
  </div>
}
@if (!e.medicareInfo?.otherDrugCoverage?.has) {
  <div
    class="flex items-center mb-4"
    >
    <div class="avatar">
      <div
        class="mask mask-squircle w-8 h-8 bg-error text-white mr-4">
        <div class="flex justify-center items-center h-full">
          <fa-icon [icon]="faTimes"></fa-icon>
        </div>
      </div>
    </div>
    <p>You don't have other Drug Coverage</p>
  </div>
}
@if (e.medicareInfo?.otherDrugCoverage?.has) {
  <div
    class="flex items-center mb-4"
    >
    <div class="avatar">
      <div
        class="mask mask-squircle w-8 h-8 bg-success text-white mr-4">
        <div class="flex justify-center items-center h-full">
          <fa-icon [icon]="faCheck"></fa-icon>
        </div>
      </div>
    </div>
    <p>You have other Drug Coverage</p>
  </div>
}
@if (!e.medicareInfo?.otherGroupCoverage?.has) {
  <div
    class="flex items-center mb-4"
    >
    <div class="avatar">
      <div
        class="mask mask-squircle w-8 h-8 bg-error text-white mr-4">
        <div class="flex justify-center items-center h-full">
          <fa-icon [icon]="faTimes"></fa-icon>
        </div>
      </div>
    </div>
    <p>You don't have other Group coverage</p>
  </div>
}
@if (e.medicareInfo?.otherGroupCoverage?.has) {
  <div
    class="flex items-center mb-4"
    >
    <div class="avatar">
      <div
        class="mask mask-squircle w-8 h-8 bg-success text-white mr-4">
        <div class="flex justify-center items-center h-full">
          <fa-icon [icon]="faCheck"></fa-icon>
        </div>
      </div>
    </div>
    <p>You have other Group coverage</p>
  </div>
}
<!-- <tr>
<td>OtherGroupCoverage otherGroupCoverage</td>
<td>
  <table class="table">
    <tr>
      <td>string name</td>
      <td>{{ i?.otherGroupCoverage?.name }}</td>
    </tr>
    <tr>
      <td>string policyId</td>
      <td>{{ i?.otherGroupCoverage?.policyId }}</td>
    </tr>
    <tr>
      <td>bool coverageIncludesRx</td>
      <td>{{ i?.otherGroupCoverage?.coverageIncludesRx }}</td>
    </tr>
    <tr>
      <td>string groupPolicyId</td>
      <td>{{ i?.otherGroupCoverage?.groupPolicyId }}</td>
    </tr>
  </table>
</td>
</tr> -->
</div>
</div>
</div>
@if (e.medicareInfo?.pcp; as p) {
  <div class="card">
    <div class="card-body">
      <div>
        <div class="card-title mb-4">
          <fa-icon [icon]="faStethoscope"></fa-icon> Doctor
        </div>
        @if (!p.wishDesignatePCP) {
          <div class="flex items-center mb-4">
            <div class="avatar">
              <div
                class="mask mask-squircle w-8 h-8 bg-error text-white mr-4">
                <div class="flex justify-center items-center h-full">
                  <fa-icon [icon]="faTimes"></fa-icon>
                </div>
              </div>
            </div>
            <p>You don't have a designated Primary Care Physician</p>
          </div>
        }
        @if (p.wishDesignatePCP) {
          <div class="flex items-center mb-4">
            <div class="avatar">
              <div
                class="mask mask-squircle w-8 h-8 bg-success text-white mr-4">
                <div class="flex justify-center items-center h-full">
                  <fa-icon [icon]="faCheck"></fa-icon>
                </div>
              </div>
            </div>
            <p>You have a designated Primary Care Physician</p>
          </div>
        }
        @if (p.isEstablishedPatient) {
          <div class="flex items-center mb-4">
            <div class="avatar">
              <div
                class="mask mask-squircle w-8 h-8 bg-success text-white mr-4">
                <div class="flex justify-center items-center h-full">
                  <fa-icon [icon]="faCheck"></fa-icon>
                </div>
              </div>
            </div>
            <p>You are an established Patient</p>
          </div>
        }
        @if (!p.isEstablishedPatient) {
          <div class="flex items-center mb-4">
            <div class="avatar">
              <div
                class="mask mask-squircle w-8 h-8 bg-error text-white mr-4">
                <div class="flex justify-center items-center h-full">
                  <fa-icon [icon]="faTimes"></fa-icon>
                </div>
              </div>
            </div>
            <p>You are no established Patient</p>
          </div>
        }
        @if (p.wishDesignatePCP) {
          <h3 class="mb-4">{{ p.name }}</h3>
        }
        <!-- @if (!production) {
        <app-loading *ngIf="loadingDoc"></app-loading>
        <div *ngIf="planDoc && planDoc[e.id]; let d">
          <div class="flex flex-wrap">
            <div
              *ngIf="d.medSchool"
              class="badge badge-neutral mr-2 mb-2">
              <fa-icon [icon]="faSchool" class="mr-2"></fa-icon> Med
              School:
              {{ d.medSchool }}
            </div>
            <div
              *ngIf="d.graduationYear"
              class="badge badge-neutral mr-2 mb-2">
              <fa-icon [icon]="faGraduationCap" class="mr-2"></fa-icon>
              Graduated:
              {{ d.graduationYear }}
            </div>
          </div>
          <h4>Organizations</h4>
          <div
            class="card card-compact"
            *ngFor="let o of d.organisations">
            <div class="card-body">
              <div class="card-title">
                {{ o.name }}
              </div>
              <p>
                <fa-icon [icon]="faLocationDot"></fa-icon>
                {{ o.address?.line1 ?? '' }}
                {{ o.address?.line2 ?? '' }}
                {{ o.address?.zip ?? '' }}
                {{ o.address?.city ?? '' }}
                {{ o.address?.county ?? '' }}
                {{ o.address?.state ?? '' }}
              </p>
              <div class="badge badge-primary" *ngIf="o.telehealth">
                <fa-icon [icon]="faCheck"></fa-icon> Offers Telehealth
              </div>
              <h4>Doctors Specialties</h4>
              <div class="flex flex-wra">
                <div class="badge badge-primary mr-2 mb-2">
                  {{ o.primarySpecialty }}
                </div>
                <div
                  *ngFor="let s of o.secondarySpecialties"
                  class="badge badge-neutral mr-2 mb-2">
                  {{ s }}
                </div>
              </div>
            </div>
          </div>
        </div>
        } -->
      </div>
    </div>
  </div>
}
</div>
<!-- @if (!production) {
<div class="card mb-4">
  <div class="card-body">
    <div class="card-title">
      <fa-icon [icon]="faPills"></fa-icon>Drugs
    </div>
    <p>Check if your drugs are included in your plan</p>
    <app-autocomplete-s
      title="Drug Name"
      placeholder="e.g. Ibuprofen"
      index="drugs"
      bindLabel="rxnorm_name"
      type="multiselect"
      bindValue="rxcui"
      type="one"
      [value]="drugSearchTerm"
      (valueChange)="searchAndAddDrug(e.id, $event)">
    </app-autocomplete-s>
    <div *ngIf="drugs">
      <div class="card card-compact mb-4" *ngFor="let d of drugs[e.id]">
        <div class="card-body">
          <h3>
            {{ d.drug?.rxnav_str ?? 'N/A' }}
          </h3>
          <div
            class="grid md:grid-cols-2"
            *ngIf="d.status !== 'notCovered'">
            <div class="flex flex-row items-center mb-2">
              <div class="avatar">
                <div
                  class="mask mask-squircle w-8 h-8 bg-war text-white mr-2 bg-success">
                  <div class="flex justify-center items-center h-full">
                    <fa-icon [icon]="faCheck"></fa-icon>
                  </div>
                </div>
              </div>
              <p>Drug is included</p>
            </div>
            <div class="flex flex-row items-center mb-2">
              <div class="avatar">
                <div
                  class="mask mask-squircle w-8 h-8 bg-war text-white mr-2"
                        [class.bg-success]="
                          (d.prior_authorization_yn ?? d.priorAuth) !== '1'
                        "
                        [class.bg-error]="
                          (d.prior_authorization_yn ?? d.priorAuth) === '1'
                        ">
                  <div class="flex justify-center items-center h-full">
                    <i
                      class="far"
                            [class.fa-check]="
                              (d.prior_authorization_yn ?? d.priorAuth) === '1'
                            "
                            [class.fa-xmark]="
                              (d.prior_authorization_yn ?? d.priorAuth) !== '1'
                            "></i>
                  </div>
                </div>
              </div>
              <p
                *ngIf="(d.prior_authorization_yn ?? d.priorAuth) === '1'">
                Needs prior authorization
              </p>
              <p
                *ngIf="(d.prior_authorization_yn ?? d.priorAuth) !== '1'">
                Needs no prior authorization
              </p>
            </div>
            <div class="flex flex-row items-center mb-2">
              <div class="avatar">
                <div
                  class="mask mask-squircle w-8 h-8 bg-war text-white mr-2"
                        [class.bg-success]="
                          (d.step_therapy_yn ?? d.stepThrpy) !== '1'
                        "
                        [class.bg-error]="
                          (d.step_therapy_yn ?? d.stepThrpy) === '1'
                        ">
                  <div class="flex justify-center items-center h-full">
                    <i
                      class="far"
                            [class.fa-check]="
                              (d.step_therapy_yn ?? d.stepThrpy) === '1'
                            "
                            [class.fa-xmark]="
                              (d.step_therapy_yn ?? d.stepThrpy) !== '1'
                            "></i>
                  </div>
                </div>
              </div>
              <p *ngIf="(d.step_therapy_yn ?? d.stepThrpy) !== '1'">
                Includes no Step Therapy
              </p>
              <p *ngIf="(d.step_therapy_yn ?? d.stepThrpy) === '1'">
                Includes Step Therapy
              </p>
            </div>
            <div class="flex flex-row items-center mb-2">
              <div class="avatar">
                <div
                  class="mask mask-squircle w-8 h-8 bg-war text-white mr-2"
                        [class.bg-success]="
                          (d.limited_access_yn ?? d.limAcc) !== '1'
                        "
                        [class.bg-error]="
                          (d.limited_access_yn ?? d.limAcc) === '1'
                        ">
                  <div class="flex justify-center items-center h-full">
                    <i
                      class="far"
                            [class.fa-check]="
                              (d.limited_access_yn ?? d.limAcc) === '1'
                            "
                            [class.fa-xmark]="
                              (d.limited_access_yn ?? d.limAcc) !== '1'
                            "></i>
                  </div>
                </div>
              </div>
              <p *ngIf="(d.limited_access_yn ?? d.limAcc) !== '1'">
                Has no limited access
              </p>
              <p *ngIf="(d.limited_access_yn ?? d.limAcc) === '1'">
                Has limited access
              </p>
            </div>
            <div class="flex flex-row items-center mb-2">
              <div class="avatar">
                <div
                  class="mask mask-squircle w-8 h-8 bg-war text-white mr-2"
                        [class.bg-success]="
                          (d.quantity_limit_yn ?? d.qntyLim) !== '1'
                        "
                        [class.bg-error]="
                          (d.quantity_limit_yn ?? d.qntyLim) === '1'
                        ">
                  <div class="flex justify-center items-center h-full">
                    <i
                      class="far"
                            [class.fa-check]="
                              (d.quantity_limit_yn ?? d.qntyLim) === '1'
                            "
                            [class.fa-xmark]="
                              (d.quantity_limit_yn ?? d.qntyLim) !== '1'
                            "></i>
                  </div>
                </div>
              </div>
              <p *ngIf="(d.quantity_limit_yn ?? d.qntyLim) !== '1'">
                Has no quantity limit
              </p>
              <p *ngIf="(d.quantity_limit_yn ?? d.qntyLim) === '1'">
                Has quantity limit
                <span *ngIf="(d.quantity_limit_yn ?? d.qntyLim) === '1'">
                  : {{ d.quantity_limit_days ?? d.qntyLimDays }} days
                </span>
                <span *ngIf="(d.quantity_limit_yn ?? d.qntyLim) === '1'">
                  , {{ d.quantity_limit_amount ?? d.qntyLimAmount }} x
                </span>
              </p>
            </div>
          </div>
          <p
                  *ngIf="
                    d.status !== 'notCovered' &&
                    (d.tier_level_value || d.tierLvlVal)
                  ">
            <b>Tier:</b> {{ d.tier_level_value ?? d.tierLvlVal }}
          </p>
          <div
            class="flex flex-row items-center mb-2"
            *ngIf="d.status === 'notCovered'">
            <div class="avatar">
              <div
                class="mask mask-squircle w-8 h-8 bg-war text-white mr-2 bg-error">
                <div class="flex justify-center items-center h-full">
                  <fa-icon [icon]="faXmark"></fa-icon>
                </div>
              </div>
            </div>
            <p>Not included</p>
          </div>
          <div class="absolute top-4 right-4">
            <button class="btn btn-primary" (click)="removeDrug(e.id, d)">
              <fa-icon [icon]="faTrashAlt"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
} -->
<!-- <div class="card mb-4">
<div class="card-body">
  <div class="card-title">More Infos Consumer</div>
  <table>
    <tr>
      <td>contactId</td>
      <td>{{ e.consumer?.contactId }}</td>
    </tr>
    <tr>
      <td>firstName</td>
      <td>{{ e.consumer?.firstName }}</td>
    </tr>
    <tr>
      <td>lastName</td>
      <td>{{ e.consumer?.lastName }}</td>
    </tr>
    <tr>
      <td>phone</td>
      <td>{{ e.consumer?.phone }}</td>
    </tr>
    <tr>
      <td>email</td>
      <td>{{ e.consumer?.email }}</td>
    </tr>
    <tr>
      <td>gender</td>
      <td>{{ e.consumer?.gender }}</td>
    </tr>
    <tr>
      <td>hicn</td>
      <td>{{ e.consumer?.hicn }}</td>
    </tr>
    <tr>
      <td>medicareNumber</td>
      <td>{{ e.consumer?.medicareNumber }}</td>
    </tr>
    <tr>
      <td>additionalEmails</td>
      <td>{{ e.consumer?.additionalEmails }}</td>
    </tr>
    <tr>
      <td>additionalPhones</td>
      <td>{{ e.consumer?.additionalPhones }}</td>
    </tr>
    <tr>
      <td>address</td>
      <td>{{ e.consumer?.address }}</td>
    </tr>
    <tr>
      <td>street</td>
      <td>{{ e.consumer?.street }}</td>
    </tr>
    <tr>
      <td>city</td>
      <td>{{ e.consumer?.city }}</td>
    </tr>
    <tr>
      <td>state</td>
      <td>{{ e.consumer?.state }}</td>
    </tr>
    <tr>
      <td>zip</td>
      <td>{{ e.consumer?.zip }}</td>
    </tr>
    <tr>
      <td>birthDate</td>
      <td>{{ e.consumer?.birthDate }}</td>
    </tr>
  </table>
</div>
</div> -->
</div>
}
</div>
}
